import { docLink } from "@common/utils/common-utils";
import { constants } from "js-common/core/common";

export const BG_SESSION_COOKIE_NAME = "bg.session";

export const ALL_SUPPORTED_FORMATS = [...constants.ALL_SUPPORTED_FORMATS];

export const TRANSFORMATION_SUPPORTED_FORMATS = [
    ...constants.TRANSFORMATION_SUPPORTED_FORMATS,
    ...constants.TRANSFORMATION_SUPPORTED_FORMATS.map((item) => item.toUpperCase()),
];

// .webp, .jpeg, .png, .jpg, .avif, .pdf, .tiff, .tif, .bmp
export const IMAGE_FORMATS_FILE_ACCEPT = constants.TRANSFORMATION_SUPPORTED_FORMATS.map(
    (format) => `.${format}`,
).join(", ");

export const TRANSFORMATION_SUPPORTED_FORMATS_REGEX = new RegExp(
    "^(" + TRANSFORMATION_SUPPORTED_FORMATS.join("|") + ")$",
);

export const THUMBNAIL_FILE_FORMAT_MAX_SIZE = 6;

export const ANONYMOUS_ID_PARAM_KEY = "anonymous_id";
export const SEGMENT_ANONYMOUS_ID_KEY = "ajs_anonymous_id";

export const mergeTransformation = "merge";
export const transformationOperation = "transformation";

export const CUSTOM_TYPE = "custom";
export const SKIP_CAPTCHA_PREFIX = "skipCode:";
export const ENTERPRISE_CALENDLY_LINK =
    "https://calendly.com/aafiya_patel/pixelbin-io-platform-demo?utm_source=EQ_Pixelbin_payment&utm_medium=Pixelbin&utm_campaign=Book+A+Demo";

export const ENTERPRISE_CALENDLY_ERASE_LINK =
    "https://calendly.com/aafiya_patel/erase-bg-product-overview";

export const ENTERPRISE_CALENDLY_UPSCALE_LINK =
    "https://calendly.com/aafiya_patel/upscale-media-product-overview";

export const ENTERPRISE_CALENDLY_WATERMARK_LINK =
    "https://calendly.com/aafiya_patel/watermark-remover-product-overview";

export const PIXELBIN_STORAGE_TYPE = "pixelbin-storage";
export const ALLOWED_DATASOURCES = [
    "pixelbin-storage",
    "google-cloud-storage",
    "s3",
    "firebase-storage",
    "dropbox",
    "azure-blob-storage",
];

// export const VALID_DICOM_VIEWS = ["A4C", "A3C", "A2C", "PLAX", "PSAX"];
export const VALID_DICOM_VIEWS = [
    "A2C",
    "A3C",
    "A4C",
    "A5C",
    "Apical_Doppler",
    "Doppler_Parasternal_Long",
    "Doppler_Parasternal_Short",
    "PLAX",
    "PSAX",
    "SSN",
    "Subcostal",
];
export const ECG_FILE_FORMATs = ".png, .jpeg, .jpg, .pdf, .dat, .dcm, .xml";
export const ECHO_FILE_FORMATs = ".dcm";

export const THUMBS_UP = "thumbs_up";
export const THUMBS_DOWN = "thumbs_down";

export const PRODUCT_DOCS_LINK = "https://docs.gauze.health";

export const ORG_ROUTE_REGEX = /^\/org\/([^/]+)\/.*/;

export const docs = {
    url_structure: docLink("/url-structure/"),
    presets: docLink("/presets/"),
};

export const BULK_PLAYGROUND_UPLOAD_CONCURRENCY_LIMIT = 5;
export const BULK_PLAYGROUND_TRANSFORMATION_CONCURRENCY_LIMIT = 1;
export const BULK_PLAYGROUND_UPLOAD_SOURCE = "bulk-playground";
export const BULK_PLAYGROUND_PAGE_SIZE = 10;
export const BULK_PLAYGROUND_BATCH_SIZE_LIMIT = 50;

// Partner Stack Constants
const PS_STORAGE_KEY = "partnerStackQueryParams";
const PS_RENAME_MAP = { ps_xid: "xid", ps_partner_key: "partner_key" };
const PS_QUERY_PARAMS = ["xid", "partner_key", "ps_xid", "ps_partner_key"];
const PS_QUERY_STORAGE_TYPE = "local"; // local or session | local for localStorage and session for session storage

export const PS_QUERY_OPTIONS = {
    storageKey: PS_STORAGE_KEY,
    storageType: PS_QUERY_STORAGE_TYPE,
    renameMap: PS_RENAME_MAP,
    include: PS_QUERY_PARAMS,
};

export const OPENAI_OAUTH_QUERY_OPTIONS = {
    storageKey: "openaiOauthQueryParams",
    storageType: "local",
    renameMap: [],
    include: ["state", "redirect_uri", "client_id", "scope"],
};

export const INVOICE_STATUSES = {
    OPEN: "open",
    DRAFT: "draft",
    PAID: "paid",
    VOID: "void",
    UNCOLLECTIBLE: "uncollectible",
    PROCESS_FAILED: "process_failed", // when invoice is paid but got error in process
};

export const propertySearchTag = "pixelbin_console";

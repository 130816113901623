import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import store from "./common/redux/store";
import { GlobalStyle } from "./GlobalStyle";
import App from "./App";
import { Provider } from "react-redux";
import "./common/events";
import { AppProvider } from "./AppContext";
import { ThemeProvider, NotificationProvider } from "fds-web";
import { BrowserRouter, useLocation } from "react-router-dom";
import { EchoPreviewProvider } from "./pages/EchoPreviewContext/index";

const themeObject = {
    font: {
        fontFamily: "",
    },
    spacing: {
        "dimensions-28": "",
    },
    breakpoints: {},
    color: [
        {
            name: "theme 1",
            value: {
                light: {
                    "fds-primary-10": "#F7FCFC",
                    "fds-primary-20": "#E6FAF7",
                    "fds-primary-30": "#A6F5E9",
                    "fds-primary-40": "#31CCB5",
                    "fds-primary-50": "#278476",
                    "fds-primary-60": "#175249",
                    "fds-primary-70": "#0F332E",
                    "fds-primary-80": "#081A17",

                    // ** Below colors given to undo effect of dark theme when switching to light theme. Same colors are used in dark theme to apply dark theme.
                    "fds-background-surface-0": "#ffffff",
                    "fds-neutrals-grey-20": "#f5f5f5",
                    "fds-neutrals-grey-40": "#e0e0e0",
                    "fds-text-default": "#141414",
                    "fds-text-subdued-1": "#000000a5",
                },
            },
        },
    ],
};

const darkTheme = {
    font: {
        fontFamily: "",
    },
    spacing: {
        "dimensions-28": "",
    },
    breakpoints: {},
    color: [
        {
            name: "theme 2",
            value: {
                dark: {
                    "fds-primary-10": "#1B2927",
                    "fds-primary-20": "#34403E",
                    "fds-primary-30": "#1F4741",
                    "fds-primary-40": "#23665D",
                    "fds-primary-50": "#278476",
                    "fds-primary-60": "#5CB8AC",
                    "fds-primary-70": "#92D6CE",
                    "fds-primary-80": "#D6FFFA",

                    "fds-background-surface-0": "#262627",
                    "fds-neutrals-grey-20": "#19191a",
                    "fds-neutrals-grey-40": "#5a5a5a",
                    "fds-text-default": "#f0f0f0",
                    "fds-text-subdued-1": "#ffffffc6",
                    // "fds-neutrals-grey-60": "#b5b5b5",
                    // "fds-neutrals-grey-80": "#ffffffc6",
                    // "fds-neutrals-grey-100": "#f0f0f0",
                    // "fds-neutrals-black": "#141414",
                },
            },
        },
    ],
};

const AppWithDynamicTheme = () => {
    const location = useLocation();
    const [currentTheme, setCurrentTheme] = useState(themeObject);
    const [currentMode, setCurrentMode] = useState("light");

    useEffect(() => {
        const isWorkspaceRoute = location.pathname.includes("workspace");
        setCurrentTheme(isWorkspaceRoute ? darkTheme : themeObject);
        setCurrentMode(isWorkspaceRoute ? "dark" : "light");
    }, [location.pathname]);

    return (
        <>
            <ThemeProvider theme={currentTheme} mode={currentMode} />
            <GlobalStyle />
            <App />
        </>
    );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <NotificationProvider autoClose duration={2000} position="bottom-right">
                <EchoPreviewProvider>
                    <AppProvider>
                        {/* <React.StrictMode> */}
                        <AppWithDynamicTheme />
                        {/* </React.StrictMode> */}
                    </AppProvider>
                </EchoPreviewProvider>
            </NotificationProvider>
        </BrowserRouter>
    </Provider>,
);
